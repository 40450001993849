<template>
  <div :class="[fgClass]">
    <div :class="[directionClassification[direction ?? 'bottom'], bgClass]">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
type DirectionOptions = 'top' | 'both' | 'bottom';

defineProps<{
  direction?: DirectionOptions
  fgClass?: string
  bgClass?: string
}>();

const directionClassification : Record<DirectionOptions, string> = {
  top: 'rounded-t-2xl lg:rounded-t-[64px]',
  both: 'rounded-2xl lg:rounded-[64px]',
  bottom: 'rounded-b-2xl lg:rounded-b-[64px]',
};
</script>
